div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #0bbf41;
  color: #fff;
  font-size: 1em;
}
div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-cancel) {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #4286f7;
  color: #fff;
  font-size: 1em;
}
div:where(.swal2-container) div:where(.swal2-popup) {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: 2px solid black;
  border-radius: 14px;
  background: #e3f4d6;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
  filter: drop-shadow(2px 4px 6px black);
}
div:where(.swal2-container) img:where(.swal2-image) {
  max-width: 100%;
  margin: 2em auto 1em;
  border-radius: 7px;
}
div:where(.swal2-container) h2:where(.swal2-title) {
  position: relative;
  max-width: 100%;
  margin: 0;
  /* padding: .8em 1em 0; */
  color: inherit;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  text-transform: uppercase;
}
div:where(.swal2-container) .swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 1em 1.6em .3em;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  letter-spacing: 1px;
}
div:where(.swal2-container) img:where(.swal2-image) {
  width: 22rem;
  margin: 2em auto 1em;
  border-radius: 7px;
  height: auto;
  filter: drop-shadow(2px 4px 6px black);
}



/* Base Styles */

.menu {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  /* Header Styles */
  .menu-headerr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .menu-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    width: 15rem;
    text-align: left;
    font-weight: 700;
  }
  .menu-title-span{
    color: #173b03;
  }
  
  .menu-description {
    font-size: 20px;
    color: #000000;
    font-weight: 200;
    width: 22rem;
    text-align: right;
  }
  
  /* Toggle Buttons Styles */
  .menu-toggles {
    text-align: center;
    margin-bottom: 75px;
  }
  
  .toggle-button {
    background-color: #e3f4d6;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 1rem;
    color: #010101;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 600;
    filter: drop-shadow(2px 4px 6px black);
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .toggle-button.active {
    background-color: #173b03;
    color: white;
  }
  
  .toggle-button:hover {
    background-color: #173b03;
    color: white;
  }
  
  .menu-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 58px;
    justify-items: center;
    opacity: 1;
    transition: opacity 0.1s ease;
    justify-content: center;
    margin-right: 24px;
}
  
  /* Hidden state for menu cards */
  .menu-grid.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .menu-card {
    position: relative;
    background-color: #e3f4d6;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 14rem;
    padding: 15px;
    border: 1px solid #3c4e2f;
    transition: box-shadow 0.3s ease;
    filter: drop-shadow(2px 4px 6px #173b03);
}
  
  .menu-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Image Styles */
  .card-image {
    position: absolute;
    top: -33px;
    right: -33px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #3c4e2f;
    border: 4px solid white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 2;
    filter: drop-shadow(0px 0px 6px #173b03);
    animation: borderAnimation 2s infinite; /* Ensure this animation is defined elsewhere */
  }
  
  .card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Preserves aspect ratio */
    padding: 5px;
    transition: transform 0.3s ease; /* Smooth scaling transition */
  }
  
  .card-image img:hover {
    transform: scale(1.1); /* Scale up by 10% */
  }
  
  /* Content Styles */
  .card-content {
    padding: 5px;
    position: relative; /* Ensures content is above the image */
    z-index: 1; /* Ensures content is above the image */
  }
  
  .dish-name {
    font-size: 1rem;
    margin: 0;
    color: #333;
    text-transform: uppercase;
    font-weight: 800;
  }
  .p-d{
    margin-bottom: 0;
  }
  
  .dish-rating {
    display: flex;
    gap: 2px;
    margin: 10px 0;
  }
  
  .dish-rating .fa-star {
    color: #ff9900;
    font-size: 0.8rem;
  }
  .pr {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}
  
  .dish-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
  }
  
  .order-button2 {
    background-color: #173b03;
    color: white;
    border: none;
    border-radius: 16px;
    padding: 6px 8px;
    cursor: pointer;
    font-size: 13px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .order-button2:hover {
    background-color: #159102;
    transform: translateY(-2px);
  }
  

  
  @media only screen and (max-width: 767px){
    .menu-headerr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      flex-direction: column;
  }
  .menu-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
    font-weight: 700;
}
.menu-description {
  font-size: 20px;
  color: #000000;
  font-weight: 200;
  width: 100%;
  text-align: center;
}
.menu-card {
  position: relative;
  background-color: #e3f4d6;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 17.4rem;
  padding: 15px;
  border: 1px solid #3c4e2f;
  transition: box-shadow 0.3s ease;
  filter: drop-shadow(2px 4px 6px #173b03);
}
div:where(.swal2-container) h2:where(.swal2-title) {
  position: relative;
  max-width: 100%;
  margin: 0;
  /* padding: .8em 1em 0; */
  color: inherit;
  font-size: 1.1em;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  text-transform: uppercase;
}
div:where(.swal2-container) img:where(.swal2-image) {
  width: 11rem;
  margin: 2em auto 1em;
  border-radius: 7px;
  height: auto;
  filter: drop-shadow(2px 4px 6px black);
}
div:where(.swal2-container) .swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 1em 1.6em .3em;
  overflow: auto;
  color: inherit;
  font-size: 0.9em;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  letter-spacing: 1px;
}
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .menu-card {
      position: relative;
      background-color: #e3f4d6;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 18rem;
      padding: 15px;
      border: 1px solid #3c4e2f;
      transition: box-shadow 0.3s ease;
      filter: drop-shadow(2px 4px 6px #173b03);
  }
  }