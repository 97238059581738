@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dancing+Script:wght@400..700&display=swap');

.about-section {
    width:100%;
    margin: 0 auto;
    background-image: url('../Images/bg.jpg'); 
    background-size: cover;
    padding: 50px 20px;
    text-align: center;
    background-color: #f9f9f9;
  }
.about-header{
    padding-top: 4rem;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  
  .about-header h5 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .about-header p {
    font-size: 16px;
    color: #666;
    margin-bottom: 40px;
    width: 44%;
}
  
  .about-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
  
  .left-side {
    width: 50%;
    text-align: left;
  }
  
  .history-heading {
    font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight:600;
  font-style:italic;
    color:#fe4b4b;
    margin-bottom: 20px;
  }
  
  .left-side p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    width: 100%;
}
  
  .right-side {
    width: 45%;
    position: relative;
  }
  
  .smooth-image {
    width:25rem;
    height: auto;
    border-radius: 8px;
    transition: opacity 1.5s ease-in-out;
    object-fit: cover;
  }
  
  .right-side img.fade-out {
    opacity: 0;
  }
  
  /* Animations */
  .about-content {
    animation: fadeInUp 1s ease-in-out;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  











  .about-section-aa {
    padding: 70px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding-bottom: 80px;
}
  
.about-container-aa {
  display: flex;
  gap: 40px;
  max-width: 1200px;
  width: 100%;
  align-items: center;
}
  
  .about-left-aa {
    flex: 1;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    filter: drop-shadow(2px 4px 6px black);
   
  }
  
  .image-slider-aa {
    position: relative;
  }
  
  .slider-image-aa {
    width: 100%;
    height: auto;
    transition: transform 0.8s ease-in-out;
  }
  
  .arrow-aa {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 15px;
    cursor: pointer;
    background-color: rgb(0 0 0 / 31%);
    border-radius: 50%;
    transition: background-color 0.3s ease;
    color: #ffffff;
    border: 1px solid #ffffff;
}
  
  .left-arrow-aa {
    left: 20px;
  }
  
  .right-arrow-aa {
    right: 20px;
  }
  
  .arrow-aa:hover {
    background-color: rgba(255, 255, 255, 0.7);
    color: #333;
  }
  
  .about-right-aa {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
   
    border-radius: 10px;
   
  }
  
  .about-right-aa p {
    font-size: 18px;
    line-height: 1.8;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-right-aa p:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 768px) {
    .about-container-aa {
      flex-direction: column;
      gap: 20px;
    }
  }
  
  .heading2 {
    position: relative;
    text-align: center;
    margin-bottom: 0px;
    display: inline-block;
  }
  
  .heading2 h2 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 20px;
  }
  
  .decorative-svg {
    width: 200px; /* Adjust size of the SVG */
    height: auto;
    vertical-align: middle; /* Align SVG vertically with the heading text */
  }
  
  .decorative-svg.left {
    margin-right: 10px; /* Adjust spacing between the SVG and text */
  }
  
  .decorative-svg.right {
    margin-left: 0px;
    margin-bottom: 0px;
}
.right2 {
    /* width: 54%; */
    padding-left: 20px;
    /* padding: 4rem; */
    /* display: flex; */
    flex-direction: column;
    /* justify-content: center; */
}













.post-container {
    position: relative;
    width: 100%;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  
}
  
  .post-image img {
    width: 100%;
    height: auto;
    display: block;
    
  }
  
  .post-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    transition: background 0.3s ease;
  }
  
  .post-overlay:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .post-heading {
    margin: 0;
    font-size: 5em;
    font-weight: bold;
    text-align: left;
    animation: fadeIn 1s ease-out;
    width: 45rem;
}
  
.post-text {
    margin-top: 25px;
    font-size: 1.5em;
    text-align: left;
    animation: fadeInUp 1s ease-out;
    width: 26rem;
}
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  





  .abdetail-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #e3f4d6;
    animation: fadeIn 1.5s ease-in-out;
}

.left-ab {
    width: 61%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    animation: slideInLeft 1s ease-in-out;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.img-1 {
    width: 39%;
    height: 23rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 4px solid #ff6600;
    filter: drop-shadow(2px 4px 6px black);

}
.img-2 {
    width: 39%;
    height: 23rem;
    border-radius: 10px;
    filter: drop-shadow(2px 4px 6px black);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 4rem;
    border: 4px solid #ff6600;
}



.right-ab {
    width: 40%;
    animation: slideInRight 1s ease-in-out;
}

.about-heading {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.main-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #444;
}

.description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
}
.hours {
    font-size: 1.1rem;
    font-weight: bold;
    color: #ff6600;
    margin-bottom: 15px;
    background-color: #f7f7f7;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    filter: drop-shadow(2px 4px 6px black);
}

.phone-number {
    font-size: 1.8rem;
    font-weight: bold;
    color: #ff6600;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}



@media only screen and (max-width: 767px){
    .post-heading {
        margin: 0;
        font-size: 1em;
        font-weight: bold;
        text-align: left;
        animation: fadeIn 1s ease-out;
        width: 100%;
    }
    .post-text {
        margin-top: 25px;
        font-size: 1em;
        text-align: left;
        animation: fadeInUp 1s ease-out;
        width: 11rem;
    }
    .abdetail-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: #e3f4d6;
        animation: fadeIn 1.5s ease-in-out;
        flex-direction: column;
    }
    .left-ab {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
        animation: slideInLeft 1s ease-in-out;
        justify-content: space-between;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .img-1 {
        width: 49%;
        height: 13rem;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 4px solid #ff6600;
        filter: drop-shadow(2px 4px 6px black);
    }
    .img-2 {
        width: 49%;
        height: 13rem;
        border-radius: 10px;
        filter: drop-shadow(2px 4px 6px black);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-top: 4rem;
        border: 4px solid #ff6600;
    }
    .about-header {
      padding-top: 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  .about-header p {
    font-size: 16px;
    color: #666;
    margin-bottom: 40px;
    width: 90%;
}
.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.left-side {
  width: 100%;
  text-align: left;
}
.right-side {
  width: 100%;
  position: relative;
}
.smooth-image {
  width: 18rem;
  height: auto;
  border-radius: 8px;
  transition: opacity 1.5s ease-in-out;
  object-fit: cover;
}
.about-section-aa {
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding-bottom: 20px;
}
    .right-ab {
        width: 100%;
        animation: slideInRight 1s ease-in-out;
    }

    .heading2 h2 {
      font-size: 9px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
      display: inline-block;
      padding: 0 4px;
  }
  .decorative-svg {
    width: 65px;
    height: auto;
    vertical-align: middle;
    padding-left: 0;
}
.about-right-aa {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  border-radius: 10px;
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
    .left-ab {
        width: 70%;
        display: flex;
        flex-direction: row;
        gap: 20px;
        animation: slideInLeft 1s ease-in-out;
        justify-content: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }  
    .img-1 {
        width: 49%;
        height: 23rem;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 4px solid #ff6600;
        filter: drop-shadow(2px 4px 6px black);
    }
    .img-2 {
        width: 49%;
        height: 23rem;
        border-radius: 10px;
        filter: drop-shadow(2px 4px 6px black);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-top: 4rem;
        border: 4px solid #ff6600;
    }
    .abdetail-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background-color: #e3f4d6;
        animation: fadeIn 1.5s ease-in-out;
        gap: 30px;
    }
    .main-heading {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 20px;
        color: #444;
    }
    .about-header p {
      font-size: 16px;
      color: #666;
      margin-bottom: 40px;
      width: 70%;
  }
  .right-side {
    width: 50%;
    position: relative;
}
.smooth-image {
  width: 21rem;
  height: auto;
  border-radius: 8px;
  transition: opacity 1.5s ease-in-out;
  object-fit: cover;
}
.about-section-aa {
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding-bottom: 10px;
}
}