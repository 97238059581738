.services {
    padding: 80px 20px;
    background: linear-gradient(180deg, #e3f4d6 30%, #698f55);
    text-align: center;
}
.ser-head{
    color:#173b03;
}

.services-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 50px;
}

.services-header h2 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    width: 15rem;
    text-align: left;
    font-weight: 700;
}



.services-header p {
    font-size: 20px;
    color: #000000;
    font-weight: 200;
    width: 22rem;
    text-align: right;
}

.services-cards {
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.card {
    background: #446433;
    border-radius: 30%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    padding: 30px;
    flex: 1 1;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    position: relative;
    overflow: hidden;
    filter: drop-shadow(2px 4px 6px black);
}

.icon-wrapper {
    background-color: #ff6600;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    transition: background-color 0.3s ease;
}

.card-icon {
    font-size: 2.5rem;
    color: #fff;
}

.card h3 {
    font-size: 1.5rem;
    color: #fefefe;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 800;
}

.card p {
    font-size: 1rem;
    color: #ffffff;
    line-height: 1.6;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.card:hover .icon-wrapper {
    background-color: #ff4500;
}

.card::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-color: rgba(41, 225, 25, 0.196);
    transform: rotate(45deg);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.card:hover::before {
    opacity: 1;
}





@media only screen and (max-width: 767px){
    .services {
        padding: 35px 10px;
        background: linear-gradient(180deg, #e3f4d6 30%, #698f55);
        text-align: center;
    }
    .services-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        flex-direction: column;
    }
    .services-header h2 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
        width: 100%;
        text-align: center;
        font-weight: 700;
    }
    .services-header p {
        font-size: 20px;
        color: #000000;
        font-weight: 200;
        width: 100%;
        text-align: center;
    }
    .services-cards {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-direction: column;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
    .card {
        background: #446433;
        border-radius: 5%;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        padding: 15px;
        flex: 1 1;
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        text-align: center;
        position: relative;
        overflow: hidden;
        filter: drop-shadow(2px 4px 6px black);
    }
}