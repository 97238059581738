.gallery-section {
    text-align: center;
    margin: 0px 0;
  }
  
  .gallery-section h2 {
    font-size: 36px;
    font-weight: 700;
    color: #000;
  }
  
  .gallery-section h5 {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color:#fe4b4b;
  }
  
  .gallery-grid {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: stretch;
    padding: 4rem;
}
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover img {
    transform: scale(1.1);
  }
  
  .gallery-item.long {
    width: 50%;
    height: 565px;
  }
  
  .gallery-small-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
}
  
  .gallery-item {
    height: 275px;
  }
  
  .overlay11 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover .overlay11 {
    opacity: 1;
  }
  
  .overlay11 h1 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  












  @media only screen and (max-width: 767px){
    .gallery-grid {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: stretch;
      padding: 1rem;
      flex-direction: column;
  }
  .gallery-item.long {
    width: 100%;
    height: 385px;
}
.overlay11 h1 {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
  }
 








  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .gallery-grid {
      display: flex;
      gap: 15px;
      justify-content: center;
      align-items: stretch;
      padding: 1rem;
  }
  .gallery-section {
    text-align: center;
    margin: 0;
    padding-top: 13px;
}
  }
 