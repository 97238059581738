@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&family=Tangerine:wght@400;700&display=swap');

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  transition: background-color 0.3s ease, padding 0.3s ease;
  z-index: 1000;
  
  box-shadow: none;
}

.logoimg {
  width: 9rem;
  height: auto;
  transition: width 0.3s ease;
  filter: drop-shadow(0px 0px 1px black);
  
 
  /* Apply mask as background */
  background-image: url('../../Components/Header/abstract-shape-transparent-gradient-element-with-black-midnight-mirage-color-template-psd-png-design_1059593-4722-removebg-preview.png'); /* Apply the mask */
  background-size: cover; /* Ensure the mask covers the entire logo */
  background-position: center; /* Center the background mask */
  background-repeat: no-repeat; /* Prevent repeating the mask image */
  background-size: 80%;
}


.scrolled {
  background-color: #000000e0; /* Solid background color when scrolled */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.scrolled .header-container {
  background-color: #d74646f9 !important; /* Solid background color on scroll */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.scrolled .logoimg {
  width: 6rem; /* Smaller size when scrolled */
  background-image: none;
}




  .name{
    text-decoration: none !important;
    color: white;
    text-transform: capitalize;
    font-family: "Fugaz One", sans-serif;
  font-weight: 400;
  font-style: normal;
    letter-spacing: 2px;
    font-size: 2.5rem;

  }
  .no-underline {
    text-decoration: none;
  }
  


  
  .nav-links {
    display: flex;
    gap: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  .nav-links a {
    color: #fff;
    text-decoration: none;
    position: relative;
    padding: 5px;
    font-size: 22px;
    font-weight: 700;
    text-transform:capitalize;
    font-family: "Fugaz One", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
}
  
  .nav-links a::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fe4b4b;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .nav-links a:hover::before {
    visibility: visible;
    width: 100%;
  }
  
  .order-now {
    background-color: #fe4b4b;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}
  
  .order-now:hover {
    background-color: #fe4b4b;
  }
  
  .mobile-toggle {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
  }
  
  .mobile-toggle span {
    width: 25px;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s ease;
  }
  
  .mobile-toggle.open span:nth-child(1) {
    transform: rotate(45deg) translateY(8px);
  }
  
  .mobile-toggle.open span:nth-child(2) {
    opacity: 0;
  }
  
  .mobile-toggle.open span:nth-child(3) {
    transform: rotate(-45deg) translateY(-8px);
  }
  
  @media (max-width: 768px) {
    .nav-links {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background-color: #333;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 20px;
      transform: translateX(-100%);
      display: flex;
      justify-content: center;
  }
  
    .nav-links.open {
      transform: translateX(0);
    }
  
    .mobile-toggle {
      display: flex;
    }
  }
  

  @media only screen and (max-width: 767px){
    .name {
      text-decoration: none !important;
      color: white;
      text-transform: capitalize;
      font-family: "Fugaz One", sans-serif;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 2px;
      font-size: 1.5rem;
  }
  .nav-links a {
    color: #fff;
    text-decoration: none;
    position: relative;
    padding: 5px;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: "Fugaz One", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
}
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .nav-links {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background-color: #333;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 20px;
      transform: translateX(-100%);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  }