/* Banner.css */
.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .ban {
    display: flex;
    align-items: center;
    width: 90%;
    background-image: url('https://img.freepik.com/free-vector/background-green-luxury-wave-border-modern-gradient-abstract_343694-4055.jpg?t=st=1725607413~exp=1725611013~hmac=294637d71e75cc224f6fda89cca48c8cdf5d9b2d6250cefb3ce468be6d1f8d52&w=1060'); /* Replace with your image path */
    background-size: cover; 
    background-position: center; 
    background-color: #e3f4d6; 
    height: 71vh;
    padding: 0px;
    border-radius: 20px;
    filter: drop-shadow(2px 4px 6px black);
}

.banner-span{
    color: #ffffff;
}

  
.banner-img {
    width: 34%;
    height: auto;
    margin-left: 0px;
    object-fit: cover;
    filter: drop-shadow(0px 0px 6px #404040);
}
  
  .banner-text {
    padding: 20px;
    text-align: center;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.banner-text h3 {
    font-size: 75px;
    margin-bottom: 30px;
    width: 40rem;
    font-weight: 800;
    color: aliceblue;
    line-height: 0.5;
}
.offer-img{
    width: 121px;
    height: auto;
}
  
.banner-text p {
    font-size: 27px;
    width: 34rem;
    font-weight: 400;
    color: aliceblue;
}


@media only screen and (max-width: 767px){
    .ban {
        display: flex;
        align-items: center;
        width: 100%;
        background-image: url('https://img.freepik.com/free-vector/background-green-luxury-wave-border-modern-gradient-abstract_343694-4055.jpg?t=st=1725607413~exp=1725611013~hmac=294637d71e75cc224f6fda89cca48c8cdf5d9b2d6250cefb3ce468be6d1f8d52&w=1060'); /* Replace with your image path */
        background-size: cover; 
        background-position: center;
        background-color: #e3f4d6;
        height: auto;
        padding: 0px;
        border-radius: 20px;
        filter: drop-shadow(2px 4px 6px black);
        flex-direction: column;
    }
    .banner-text p {
        font-size: 20px;
        width: 20rem;
        font-weight: 400;
    }
    .banner-text h3 {
        font-size: 37px;
        margin-bottom: 30px;
        width: 20rem;
        font-weight: 800;
        line-height: 0.5;
    }
    .banner-img {
        width: 100%;
        height: auto;
        margin-left: 43px;
        object-fit: cover;
        filter: drop-shadow(0px 0px 6px #173b03);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
    .ban {
        display: flex;
        align-items: center;
        width: 100%;
        background-image: url('https://img.freepik.com/free-vector/background-green-luxury-wave-border-modern-gradient-abstract_343694-4055.jpg?t=st=1725607413~exp=1725611013~hmac=294637d71e75cc224f6fda89cca48c8cdf5d9b2d6250cefb3ce468be6d1f8d52&w=1060'); /* Replace with your image path */
        background-size: cover; 
        background-position: center;
        background-color: #e3f4d6;
        height: auto;
        padding: 0px;
        border-radius: 20px;
        filter: drop-shadow(2px 4px 6px black);
    }
    .banner-img {
        width: 39%;
        height: auto;
        margin-left: 0px;
        object-fit: cover;
        filter: drop-shadow(0px 0px 6px #404040);
    }   
    .banner-text h3 {
        font-size: 45px;
        margin-bottom: 30px;
        width: 100%;
        font-weight: 800;
        line-height: 0.5;
    }
    .banner-text p {
        font-size: 21px;
        width: 100%;
        font-weight: 400;
    }
}