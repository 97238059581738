.poster {
    text-align: center;
    padding: 20px;
    background-color:#e3f4d6;
  }
 .up-p{
    display: flex;
    flex-direction: column;
    align-items: center;
 } 
  
 .shop-now {
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #173b03;
    color: #fff;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 22px;
}
  
  .shop-now:hover {
    background-color:#ff6600;
  }
  .poster-image {
    width: 70%;
    height: auto;
    margin: 20px 0;
    filter: drop-shadow(0px 0px 6px #173b03);
}
.up-h {
    width: 70%;
    padding: 1rem;
    font-weight: 700;
    font-size: 4rem;
}
.up-h-span{
    color: #173b03;
}
.up-para {
    font-size: 24px;
    font-weight: 200;
}
  
.icon-cards {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
  background-color: #173b03;
  padding: 20px;
  border-radius: 30px;
  margin-bottom: 15px;
}
.ivv{
  color: #e3f4d6;
}
  
  .icon-card {
    text-align: center;
  }
  
  .icon-card i {
    font-size: 2em;
    margin-bottom: 10px;
    color: white;
  }
  
  .icon-card p {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
}
  


@media only screen and (max-width: 767px){
  .poster {
    text-align: center;
    padding: 10px;
    background-color: #e3f4d6;
}
.up-h {
  width: 100%;
  padding: 1rem;
  font-weight: 700;
  font-size: 2.2rem;
}
.up-para {
  font-size: 18px;
  font-weight: 200;
}
.poster-image {
  width: 100%;
  height: auto;
  margin: 20px 0;
  filter: drop-shadow(0px 0px 6px #173b03);
}
.icon-cards {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
  background-color: #173b03;
  padding: 15px;
  border-radius: 30px;
  margin-bottom: 15px;
}
.icon-card p {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .up-h {
    width: 100%;
    padding: 1rem;
    font-weight: 700;
    font-size: 3.5rem;
} 
.poster-image {
  width: 100%;
  height: auto;
  margin: 20px 0;
  filter: drop-shadow(0px 0px 6px #173b03);
}
.icon-cards {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 90%;
  background-color: #173b03;
  padding: 20px;
  border-radius: 30px;
  margin-bottom: 15px;
}
}