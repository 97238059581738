.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


/* Prevent text selection across the whole website */

* {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard syntax */
}


body, html {
  scrollbar-width: thin; /* For Firefox */
  
}

body::-webkit-scrollbar, html::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar */
}

body::-webkit-scrollbar-track, html::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

body::-webkit-scrollbar-thumb:hover, html::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
