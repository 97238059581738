@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Martel:wght@200;300;400;600;700;800;900&display=swap');
.slider-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
    z-index: 1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.3);
    padding-bottom: 4rem;
}
  .slide-heading {
    color: #fff;
    text-transform: uppercase;
    font-size: 3.5rem;
    letter-spacing: 1px;
    font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 5px;
    transform: translateY(50px);
    opacity: 0;
    animation: slideUp 1s forwards;
  }
  
  @keyframes slideUp {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  








  @media only screen and (max-width: 767px){
    .slide-heading {
      color: #fff;
      text-transform: uppercase;
      font-size: 2rem;
      letter-spacing: 1px;
      font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 5px;
      transform: translateY(50px);
      opacity: 0;
      text-align: center;
      animation: slideUp 1s forwards;
    }
    .slider-container {
      width: 100%;
      height: 65vh;
      overflow: hidden;
  }
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65vh;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}
  }
 

  @media only screen and (min-width: 768px) and (max-width: 1023px){

  }