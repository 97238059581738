@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');
.footer {
  display: flex;
  padding: 20px;
  background-color: #333;
  color: #fff;
  font-family: Arial, sans-serif;
  animation: fadeIn 1s ease-in;
  flex-direction: column;
}
  
.footer-column {
  /* flex: 1 1; */
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

  
  .footer-logo {
    max-width: 70px;
  }
  
  .footer-text {
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}
  
  .footer-heading {
    font-size: 18px; /* Heading size */
    margin-bottom: 10px;
    position: relative; /* Required for positioning the pseudo-element */
    padding-bottom: 10px; /* Add padding to ensure space for the pseudo-element */
  }
  
  .footer-heading::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30%;
    height: 2px; /* Border thickness */
    background-color: #fff;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 0;
}
  
  .footer-links li {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
}
  
  .footer-links a:hover {
    color: #f39c12;
  }
  
  .footer-social-icons {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .social-icon {
    font-size: 32px;
    color: #fff;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .social-icon:hover {
    transform: scale(1.2);
    opacity: 0.8;
    color: #e67e22;
  }
  
  .footer-newsletter {
    display: flex;
  }
  
  .newsletter-input {
    padding: 10px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    flex: 1;
  }
  
  .subscribe-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ff6600;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .subscribe-button:hover {
    background-color: #e67e22;
    transform: scale(1.05);
  }
  
  .footer-bottom {
    margin-top: 0px;
    padding: 10px;
    text-align: center;
    border-top: 1px solid #444;
    background-color: #222;
  }
  
  .footer-copyright {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 0;
  }
  
  .footer-link {
    color: #ff6600;
    text-decoration: none;
  }
  .namef {
    text-decoration: none !important;
    color: white;
    text-transform: capitalize;
    font-family: "Fugaz One", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2px;
    font-size: 3rem;
}
  
  .footer-link:hover {
    text-decoration: none;
    color: #f39c12;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }




  @media only screen and (max-width: 767px){
    .namef {
      text-decoration: none !important;
      color: white;
      text-transform: capitalize;
      font-family: "Fugaz One", sans-serif;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 2px;
      font-size: 1.6rem;
  }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px){
      
  }
  