@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');
.statistic-section {
    padding-top: 30px;
    padding-bottom: 30px;
    background: linear-gradient(to right, #446234, #173b03);
}
.c-t{
   text-align: center;
   color: #fff;
   text-transform: capitalize;
    font-family: "Fugaz One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3.5rem;
    letter-spacing: 2px;
}
 .count-title {
    font-size: 50px;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
 }
 
 .stats-text {
    font-size: 15px;
    font-weight: normal;
    margin-top: 15px;
    margin-bottom: 0;
    text-align: center;
    color: #fafafa;
    text-transform: uppercase;
    font-weight: bold;
 }
 
 .stats-line-black {
    margin: 12px auto 0;
    width: 55px;
    height: 2px;
    background-color: #ff8902;
 }
 
 .stats-icon {
    font-size: 35px;
    margin: 0 auto;
    float: none;
    display: table;
    color: #fff;
 }
 
 @media (max-width: 992px) {
    .counter {
        margin-bottom: 40px;
    }
 }
 



 @media only screen and (max-width: 767px){
   .row>* {
      flex-shrink: 0;
      width: 49%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x)* .5);
      padding-left: calc(var(--bs-gutter-x)* .5);
      margin-top: var(--bs-gutter-y);
  }
  .count-title {
   font-size: 15px;
   font-weight: normal;
   margin-top: 10px;
   margin-bottom: 0;
   text-align: center;
   font-weight: bold;
   color: #ffffff;
}
.c-t {
   text-align: center;
   color: #fff;
   text-transform: capitalize;
   font-family: "Fugaz One", sans-serif;
   font-weight: 400;
   font-style: normal;
   font-size: 2.5rem;
   letter-spacing: 2px;
}
.stats-icon {
   font-size: 20px;
   margin: 0 auto;
   float: none;
   display: table;
   color: #fff;
}
.stats-text {
   font-size: 9px;
   font-weight: normal;
   margin-top: 15px;
   margin-bottom: 0;
   text-align: center;
   color: #fafafa;
   text-transform: uppercase;
   font-weight: bold;
}
.row {
   --bs-gutter-x: 1.5rem;
   --bs-gutter-y: 0;
   display: flex;
   flex-wrap: wrap;
   margin-top: calc(var(--bs-gutter-y)* -1);
   margin-right: calc(var(--bs-gutter-x)* -.5);
   margin-left:-15; 
   flex-direction: row;
}
 }
 
 @media only screen and (min-width: 768px) and (max-width: 1023px){
   .count-title {
      font-size: 35px;
      font-weight: normal;
      margin-top: 10px;
      margin-bottom: 0;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
  }
 }