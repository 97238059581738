.menu-section {
    padding: 2rem;
    text-align: center;
    background-color: #f2f4f1;
  }
  
  .menu-header h5 {
    font-size: 1.2rem;
    color: #ff4b4b;
  }
  
  .menu-header h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .menu-categories {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .menu-category {
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    color: #333;
    position: relative;
  }
  
  .menu-category i {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .menu-category.active p {
    color: #ff4b4b;
  }
  
  .menu-category.active::after {
    content: '';
    position: absolute;
    left: 44%;
    bottom: -53px;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: #dad9d9;
    transform: rotate(45deg);
}
  
.menu-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    width:100%;
    margin: 0 auto;
    padding: 2rem;
    background-color:#6d696938;
}
  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 1rem;
    border-radius: 8px;
   
    position: relative;
  }
  
  .menu-item-left {
    display: flex;
    align-items: center;
  }
  
  .menu-item-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  .menu-item h4 {
    font-size: 1.2rem;
    margin: 0;
    color: #333;
  }
  
  .menu-item p {
    font-size: 0.9rem;
    color: #666;
    text-align: center;
    margin-bottom: 0;
}
  
  .menu-item-right {
    text-align: right;
  }
  
  .menu-item-price {
    font-size: 1.2rem;
    color: #ff4b4b;
    font-weight: bold;
  }
  
  .menu-item-right p {
    font-size: 0.8rem;
    color: #666;
  }
  
 
  .menu-section {
    padding: 30px;
    background-color: #f9f9f9;
  }
  
  .menu-header {
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}
.c-icon{
    width: 2rem;
    height: auto;
    color: #fe4b4b;
} 
.menu-header h5 {
    font-size: 16px;
    color: #ff4b4b;
    text-transform: uppercase;
}
  
  .menu-header h2 {
    font-size: 36px;
    font-weight: 700;
    color: #000;
  }
  
  .menu-categories {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .menu-category {
    cursor: pointer;
    padding: 10px 20px;
    border: 2px solid #ddd;
    border-radius: 25px;
    transition: all 0.3s ease;
    text-align: center;
    width: 7.5rem;
}
  
  .menu-category.active {
    background-color: #000;
    color: white;
  }
  
  .menu-category p {
    margin: 5px 0;
  }
  
 
  .menu-item {
    background: #ffffff63;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}
  
.menu-item-left {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 7px;
}
.menu-item-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 0px;
  filter: drop-shadow(2px 4px 6px black);
}
  
.menu-item h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-align:center;
}
.ce {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8PX;
}
  
  .menu-item-right {
    text-align: right;
    display: flex;
    flex-direction: column;
}
  
  .menu-item-price {
    font-size: 18px;
    font-weight: bold;
  }
  
  .menu-item-extras {
    font-size: 14px;
    color: #999;
  }
  














  @media only screen and (max-width: 767px){
    .menu-categories {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-bottom: 40px;
      flex-wrap: wrap;
      align-items: center;
  }
  .menu-section {
    padding: 10px;
    background-color: #f9f9f9;
}
.menu-items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  background-color: #6d696938;
}
.menu-header h2 {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}
  }
 








  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .menu-categories {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-bottom: 40px;
      flex-wrap: wrap;
  }
  .menu-section {
    padding: 10px;
    background-color: #f9f9f9;
}
.menu-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  background-color: #6d696938;
}
.menu-category.active::after {
  content: '';
  position: absolute;
  left: 44%;
  display: none;
  bottom: -53px;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #dad9d9;
  transform: rotate(45deg);
}
  }
 