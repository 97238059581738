/* Categories.css */

.categories {
    padding: 2rem;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .categories-heading {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
}
.heading-span{
    color: #173b03;
}
  
.categories-heading h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    width: 15rem;
    text-align: left;
    font-weight: 700;
}
.categories-heading p {
    font-size: 20px;
    color: #000000;
    font-weight: 200;
    width: 22rem;
    text-align: right;
}
  
  .categories-slider {
    display: flex;
    overflow-x: auto;
    gap: 1rem;
    padding-bottom: 2.5rem !important;
    padding: 1rem 0;
    scroll-snap-type: x mandatory;
    
    -webkit-overflow-scrolling: touch;
  }
  
  /* Custom Scrollbar Styles */
  .categories-slider::-webkit-scrollbar {
    height: 8px; /* Adjust height for horizontal scrollbar */
  }
  
  .categories-slider::-webkit-scrollbar-track {
    background: #f4f4f4;
    border-radius: 10px;
  }
  
  .categories-slider::-webkit-scrollbar-thumb {
    background: #173b03;
    border-radius: 10px;
    width: 3rem;
    transition: background 0.3s ease;
  }
  
  .categories-slider::-webkit-scrollbar-thumb:hover {
    background: #173b03;
  }
  
  .card2 {
 
    border-radius: 8px;
  
    overflow: hidden;
    flex: 0 0 auto;
    width: 250px;
 
    scroll-snap-align: start;
  }
  
  .card2 img {
    width: 9rem;
    height: 150px;
    object-fit: contain;
    transition: transform 0.3s ease;
    filter: drop-shadow(2px 4px 6px black);
}
.card2 img:hover{
    transform: scale(1.05);
}
  
  .card2 h3 {
    font-size: 1.1rem;
    margin: 1rem 0;
  }
  
  .card2 .order-button {
    background-color:none;
    color: rgb(2, 2, 2);
    border: none;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .card2 .order-button:hover {
    background-color:none;
  }
  .tic{
    font-weight: 700;
    text-transform: uppercase;
  }
  

  

  @media only screen and (max-width: 767px){
    .categories {
      padding: 0.5rem;
      background-color: #f4f4f4;
      text-align: center;
  }
  .categories-heading {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.categories-heading h2 {
  font-size: 1.7rem;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;
  font-weight: 700;
}
.categories-heading p {
  font-size: 20px;
  color: #000000;
  font-weight: 200;
  width: 100%;
  text-align: center;
}
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px){
      
  }


  