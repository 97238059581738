.nn{
    padding-top: 5rem;
}
.about-section-aaa {
    padding: 25px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding-bottom: 80px;
}





.post-containern {
    position: relative;
    width: 100%;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  
}
  
  .post-imagen img {
    width: 100%;
    height: auto;
    display: block;
    
  }
  
  .post-overlayn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    transition: background 0.3s ease;
  }
  

  
  .post-headingn {
    margin: 0;
    font-size: 3.5em;
    font-weight: bold;
    text-align: left;
    animation: fadeInn 1s ease-out;
    width: 43rem;
}
  
.post-textn {
    margin-top: 25px;
    font-size: 1.5em;
    text-align: left;
    animation: fadeInUpn 1s ease-out;
    width: 45rem;
}
  
  @keyframes fadeInn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInUpn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  




  


  @media only screen and (max-width: 767px){
    .post-headingn {
      margin: 0;
      font-size: 1em;
      font-weight: bold;
      text-align: left;
      animation: fadeInn 1s ease-out;
      width: 13rem;
      margin-top: 84px;
  }
  .about-section-aaa {
    padding: 25px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding-bottom: 20px;
}
  .nn {
    padding-top: 0rem;
    padding: 10px;
}
  .post-textn {
    margin-top: 25px;
    font-size: 1em;
    text-align: left;
    animation: fadeInUpn 1s ease-out;
    width: 16rem;
}
.post-imagen img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}
  }
 




  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .post-headingn {
      margin: 0;
      font-size: 2.5em;
      font-weight: bold;
      text-align: left;
      animation: fadeInn 1s ease-out;
      width: 32rem;
  }
  .post-textn {
    margin-top: 25px;
    font-size: 1.5em;
    text-align: left;
    animation: fadeInUpn 1s ease-out;
    width: 35rem;
}
.nn {
  padding-top: 0rem;
  padding: 10px;
}
.about-section-aaa {
  padding: 25px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding-bottom: 10px;
}
  }
 