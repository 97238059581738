.footer2 {
    background-color: #1a1a1a;
    color: #f5f5f5;
    padding: 0px 0;
    font-family: 'Arial', sans-serif;
    padding-top: 40px;
}
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .about-us, .links {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.about-us h3, .find-us h4, .links h3 {
    color: #ffffff;
    font-size: 17px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
}
.find-us {
  flex: 1 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.find-us h2{
  color: #b7975a;
  font-family: "Fugaz One", sans-serif;
  font-style: italic;
}
.find-usl::after {
  content: "";
  display: block;
  width: 39px;
  height: 1px;
  background-color: #b7975a;
  margin: 23px auto 0;
}
.lof{
  width: 16rem;
  height: auto;
  filter: drop-shadow(0px 0px 0px white);
}


.add {
    margin-bottom: 0;
    font-size: 13px;
    color: #919090;
    letter-spacing: 1px;
}
.link-info{
  text-decoration: none;
  color: #ffffff;
} 
.link-info:hover{
  color: #f5f5f5;
}
.about-us p {
  font-style: italic;
  font-size: 13px;
  line-height: 1.5;
  max-width: 411px;
  color: #919090;
  margin: 0 auto;
  letter-spacing: 1px;
}
.l{
  text-decoration: none;
}
.all{
    margin-bottom: 0;
    font-size: 14px;
} 
.line-decor {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
  }
  
  .line-decor .line {
    height: 0.5px;
    width: 135px;
    background-color: #b7975a;
    margin: 0 42px;
}
  
  .line-decor .dot {
    width: 8px;
    height: 8px;
    background-color: #b7975a;
    border-radius: 50%;
  }
  
  .bottom {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 1rem;
    align-items: center;
    background-color: #046b23;
}
  .find-us .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: 24px;
  }
  .s {
    font-weight: 900;
    font-size: larger;
}
  
  .find-us .social-icons svg {
    color: #fff;
    transition: color 0.3s ease;
  }
  
  .find-us .social-icons svg:hover {
    color: #b7975a;
  }
  
  .links ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
  
  .links ul li {
    font-style: italic;
    font-size: 13px;
    line-height: 1.4;
    max-width: 300px;
    margin: 0 auto;
    color: #919090;
    letter-spacing: 1px;
    cursor: pointer;
  }
  
  .links ul li:hover {
    color: #fff;
  }
  
  .footer-bottomm {
    text-align: center;
    padding: 20px 0;
   
    margin-top: 20px;
  }
  
  .footer-linkss {
    display: flex;
    width: 12rem;
    gap: 5px;
    flex-wrap: wrap;
}
  .footer-bottom p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .back-to-top {
    display: block;
    color: #ffffff;
    margin-top: 10px;
    font-size: 14px;
    text-decoration: none;
  }
  
  .back-to-top:hover {
    color: #fff;
  }
  












  @media only screen and (max-width: 767px){
    .footer-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
      flex-direction: column;
  }
  .find-usl::after {
    content: "";
    display: block;
    width: 39px;
    height: 1px;
    background-color: #b7975a;
    margin: 14px auto 14px;
}
.lof {
  width: 16rem;
  height: auto;
  filter: drop-shadow(0px 0px 0px white);
  margin-top: 12px;
}
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px){
  
  }
