
.opening-hours-section {
    background-image: url('../Images/pp.jpg'); /* Add a similar background image */
    background-size: cover;
    color: #fff; /* White color for text */
    padding: 60px;
    text-align: center;
   background-attachment: fixed;
    font-family: 'YourFont', sans-serif; /* Use the same font family */
  }
  
  .content-wrapper {
    display: inline-block;
    background: rgba(0, 0, 0, 0.6); /* Overlay effect to make text readable */
    padding: 30px;
    border-radius: 10px;
  }
  
  .heading h2 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #ffffff; /* Gold-like color for heading */
    margin: 0;
    text-transform: uppercase;
    border-bottom: 2px solid #e6bf42; /* Decorative line */
    display: inline-block;
    padding-bottom: 10px;
  }
  
  .subtext {
    font-style: italic;
    color: #e6bf42;
    margin-top: 0px;
    font-family: 'Cursive', serif;
    font-size: 20px;
}
  
  .hourss {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 50px;
}
  
  .days-group {
    text-align: center;
  }
  
  .days-group h3 {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Cursive', serif; /* Cursive-like font for days */
    color: #e6bf42;
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .days-group p {
    font-size: 26px;
    margin: 5px 0;
    font-family: 'Monospace', serif;
}
  














@media only screen and (max-width: 767px){
  .opening-hours-section {
  
    background-size: cover;
    color: #fff;
    padding: 18px;
    text-align: center;
    background-attachment: fixed;
    font-family: 'YourFont', sans-serif;
  }
  .days-group{
    width: 8rem;
}
.content-wrapper {
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 10px;
}
.subtext {
  font-style: italic;
  color: #e6bf42;
  margin-top: 0px;
  font-family: 'Cursive', serif;
  font-size: 20px;
  margin-bottom: 0;
}
}
