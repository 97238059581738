.feedback-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    background-image: url('../Images/bg2.jpg'); 
    background-size: cover;
    margin: auto;
  }
  
  .feedback-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    transition: transform 0.5s ease-in-out;
  }
  
  .left {
    position: relative;
    width: 50%;
    padding: 4rem;
}
  
.polygon-image {
  position: relative;
  filter: drop-shadow(0px 0px 6px black);
}
  
  .polygon-img {
    width: 100%;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  
  .circular-images {
    position: absolute;
    top: 10%;
    left: 10%;
  }
  .circular-image{
    height: 0;
  }
  
  .circle-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
  }
  
  .circle-img:nth-child(1) {
    top: 10rem;
    left: 26rem;
    border: 5px solid white;
    filter: drop-shadow(0px 0px 6px black);
}
  
.circle-img:nth-child(2) {
    position: relative;
    bottom: 31rem;
    left: 19rem;
    border: 5px solid white;
    filter: drop-shadow(0px 0px 6px black);
}
  
.circle-img:nth-child(3) {
    bottom: 0rem;
    left: 78%;
    transform: translateX(-50%);
    border: 5px solid white;
    filter: drop-shadow(0px 0px 6px black);
}
  
.right {
    width: 50%;
    padding-left: 20px;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
  .rating-stars {
    display: flex;
    align-items: center;
  }
  
  .rating-stars span {
    margin-left: 10px;
  }
  
  .h3-feed {
    font-weight: 700;
    font-size: 24px;
    margin: 10px 0;
}
.menu-header2 {
    text-align: center;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}
.menu-header2 h5 {
    font-size: 16px;
    color: #ff4b4b;
    text-transform: uppercase;
}
.menu-header2 h2 {
    font-size: 36px;
    font-weight: 700;
    color: #000;
}
  
.p-feed {
    font-size: 17px;
    font-weight: 300;
    color: black;
}
.cu-p{
    color: #ff0000;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
}
.cu-name{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}
  .customer-info {
    display: flex;
    justify-content: space-between;
  }
  
  hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }
  
  .dots {
    display: flex;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: red;
  }
  
  /* Navigation buttons */
  .navigation {
    margin-top: 20px;
  }
  
  .navigation button {
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .navigation button:hover {
    background-color: #555;
  }
  hr:not([size]) {
    height: 2px;
}














@media only screen and (max-width: 767px){
  .feedback-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    transition: transform 0.5s ease-in-out;
    flex-direction: column;
  }
  .left {
    position: relative;
    width: 100%;
    padding: 2rem;
}
.right {
  width: 100%;
  padding-left: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.menu-header2 h2 {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}
.circle-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: absolute;
}
.circle-img:nth-child(3) {
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  border: 5px solid white;
  filter: drop-shadow(0px 0px 6px black);
}
.circle-img:nth-child(1) {
  top: 9rem;
  left: 80%;
  border: 5px solid white;
  filter: drop-shadow(0px 0px 6px black);
}
.circle-img:nth-child(2) {
  position: relative;
  bottom: 8rem;
  left: -2rem;
  border: 5px solid white;
  filter: drop-shadow(0px 0px 6px black);
}
}





@media only screen and (min-width: 768px) and (max-width: 1023px){
  .left {
    position: relative;
    width: 50%;
    padding: 1rem;
    display: flex;
    align-items: center;
}
.circle-img {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  position: absolute;
}
  .right {
    width: 50%;
    padding-left: 20px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.circle-img:nth-child(3) {
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  border: 5px solid white;
  filter: drop-shadow(0px 0px 6px black);
}
.circle-img:nth-child(1) {
  top: 12rem;
  left: 16rem;
  border: 5px solid white;
  filter: drop-shadow(0px 0px 6px black);
}
.circle-img:nth-child(2) {
  position: relative;
  bottom: 9rem;
  left: -1rem;
  border: 5px solid white;
  filter: drop-shadow(0px 0px 6px black);
}
}
