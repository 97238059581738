


.post-containerb {
    position: relative;
    width: 100%;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  
}
  
  .post-imageb img {
    width: 100%;
    height: auto;
    display: block;
    
  }
  
  .post-overlayb {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    transition: background 0.3s ease;
  }
  

  
  .post-headingb {
    margin: 0;
    font-size: 3.5em;
    font-weight: bold;
    text-align: left;
    animation: fadeInn 1s ease-out;
    width: 43rem;
}
.post-headingb-span{
    color: #f58532;
}  
  
.post-textb {
    margin-top: 25px;
    font-size: 1.5em;
    text-align: left;
    animation: fadeInUpb 1s ease-out;
    width: 45rem;
}
  
  @keyframes fadeInb {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInUpb {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  










  @media only screen and (max-width: 767px){
    .post-headingb {
      margin: 0;
      font-size: 0.8em;
      font-weight: bold;
      text-align: left;
      animation: fadeInn 1s ease-out;
      width: 13rem;
      margin-top: 25%;
  }
  .post-textb {
    margin-top: 3px;
    font-size: 0.7em;
    text-align: left;
    animation: fadeInUpb 1s ease-out;
    width: 13rem;
}
.post-imageb img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: fill;
}
  }
 







  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .post-headingb {
      margin: 0;
      font-size: 2em;
      font-weight: bold;
      text-align: left;
      animation: fadeInn 1s ease-out;
      width: 31rem;
  }
  .post-textb {
    margin-top: 25px;
    font-size: 1.5em;
    text-align: left;
    animation: fadeInUpb 1s ease-out;
    width: 33rem;
}
  }
 