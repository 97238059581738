.post-container1 {
    position: relative;
    width: 100%;
   width: 100%;
  
    overflow: hidden;
   
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .post-image1 img {
    width: 100%;
    height: auto;
    display: block;
    
  }
  
  .post-overlay1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    transition: background 0.3s ease;
  }
  
  .post-overlay1:hover {
    background: rgba(0, 0, 0, 0.522);
  }
  
  .post-heading1 {
    margin: 0;
    font-size: 5em;
    font-weight: bold;
    text-align: right;
    animation: fadeIn 1s ease-out;
    width: 30rem;
}
  
.post-text1 {
    margin-top: 25px;
    font-size: 1.5em;
    text-align: right;
    animation: fadeInUp 1s ease-out;
    width: 26rem;
}
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  











  .information {
    padding: 20px;
    background: linear-gradient(360deg, #ffffff 30%, #c4bfbf);
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .info-header h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    width: 15rem;
    text-align: left;
    font-weight: 700;
  }
  
  .info-header p {
    font-size: 20px;
    color: #000000;
    font-weight: 200;
    width: 22rem;
    text-align: right;
  }
  .info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
  .info-details {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}
  

  
.info-item h3 {
    font-size: 2rem;
    color: #333;
    margin: 0 0 5px 0;
    /* font-family: 'Arial', sans-serif; */
    font-weight: 800;
    text-transform: uppercase;
}
  
.info-item p {
    font-size: 1.5rem;
    color: #464343;
    margin: 0;
    /* font-family: 'Arial', sans-serif; */
}
  




@media only screen and (max-width: 767px){
  .post-heading1 {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    animation: fadeIn 1s ease-out;
    width: 100%;
    margin-top: 97px;
}
.post-text1 {
  margin-top: 6px;
  font-size: 11px;
  text-align: left;
  animation: fadeInUp 1s ease-out;
  width: 23rem;
}
.post-overlay1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  transition: background 0.3s ease;
}
.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  flex-direction: column;
}
.info-header h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;
  font-weight: 700;
}
.info-header p {
  font-size: 20px;
  color: #000000;
  font-weight: 200;
  width: 100%;
  text-align: center;
}
.info-item p {
  font-size: 1.5rem;
  color: #464343;
  margin: 0;
  /* font-family: 'Arial', sans-serif; */
  text-align: center;
}
.info-details {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-direction: column;
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .info-item p {
    font-size: 1.1rem;
    color: #464343;
    margin: 0;
    /* font-family: 'Arial', sans-serif; */
    text-align: center;
}
.post-heading1 {
  margin: 0;
  font-size: 2em;
  font-weight: bold;
  text-align: right;
  animation: fadeIn 1s ease-out;
  width: 30rem;
}
.info-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 0 0 5px 0;
  /* font-family: 'Arial', sans-serif; */
  font-weight: 800;
  text-transform: uppercase;
}
}