.provide-section {
    text-align: center;
    padding: 25px 20px;
    background-color: #f8f9fa;
  }
  
  .provide-section h2 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #333;
    width: 50%;
    font-weight: 700;
}
  
.provide-section h5 {
    font-size: 1.6rem;
    color: #000000;
    margin-bottom: 60px;
    width: 60%;
    font-weight: 200;
}
.provide-span{
    color: #173b03;
}
  
  .provide-cards {
    display: flex;
    justify-content: center;
    gap: 35px;
    flex-wrap: wrap;
  }
  .txt-div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .provide-card {
    background: #173b03;
    /* border-radius: 8px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 33px;
    width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(2px 4px 6px black);
    align-items: center;
    border-top-left-radius: 9em;
    border-top-right-radius: 9em;
}
  
  .provide-card:hover {
    transform: translateY(0px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .provide-icon {
    font-size: 4rem;
    color: #e3f4d6;
    margin-bottom: 15px;
  }
  .provide-card h3 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #ede9e9;
    font-weight: 700;
    width: 12rem;
}
  
  .provide-card p {
    font-size: 1rem;
    color: #fefefe;
  }
  



  @media only screen and (max-width: 767px){
    .provide-section h2 {
      font-size: 2.5rem;
      margin-bottom: 10px;
      color: #333;
      width: 100%;
      font-weight: 700;
  }
  .provide-section h5 {
    font-size: 1.3rem;
    color: #000000;
    margin-bottom: 60px;
    width: 100%;
    font-weight: 200;
}
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .provide-section h2 {
      font-size: 3rem;
      margin-bottom: 10px;
      color: #333;
      width: 90%;
      font-weight: 700;
  }
  .provide-section h5 {
    font-size: 1.6rem;
    color: #000000;
    margin-bottom: 60px;
    width: 75%;
    font-weight: 200;
}
  }