.review {
    padding: 50px 0;
    display: flex;
    align-content: center;
    align-items: center;
    background-color: #e3f4d6;
}
  
.review .heading {
    text-align: center;
    margin-bottom: 30px;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
}
.categories-slider2 {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 70%;
  filter: drop-shadow(2px 4px 6px black);
}
  
  .card3 {
    flex: 0 0 30%; /* Display three cards with some spacing */
    margin: 0 10px; /* Add margin between cards */
    transition: transform 0.5s ease;
    opacity: 1;
    background-color: #173b03;
    padding: 10px;
    border-radius: 10px;
    visibility: visible;
  }
  
  .card3 img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
    border: 4px solid white;
  }
  .head-span{
    color: #173b03;
  }
  .card3 h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
  }
  
  .card3 p {
    font-size: 14px;
    margin-bottom: 10px;
    color: white;
  }
  
  .stars i {
    color: #ffdd57;
  }
  

  @media only screen and (max-width: 767px){
    .card3 {
      flex: 0 0 95%; /* Display three cards with some spacing */
      margin: 0 10px; /* Add margin between cards */
      transition: transform 0.5s ease;
      opacity: 1;
      background-color: #173b03;
      padding: 10px;
      border-radius: 10px;
      visibility: visible;
    }
    .review {
      padding: 34px 0;
      display: flex;
      align-content: center;
      align-items: center;
      background-color: #e3f4d6;
      flex-direction: column;
  }
  .review .heading {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}
.categories-slider2 {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  width: 100%;
  filter: drop-shadow(2px 4px 6px black);
  gap: 1rem;
  flex-direction: column;
}
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .review .heading {
      text-align: center;
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;
      width: 13rem;
  }
  .categories-slider2 {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    filter: drop-shadow(2px 4px 6px black);
}
  }